import React from "react";
import { loadDefaultProject } from "../../reducers/tutorials";
import defaultProject from "./defaultProject.json";
import CameraModal from "./CameraModal.jsx";
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';

const initialState = {
    showCameraPopup: undefined,
}

class initialLoad extends React.Component {

    constructor (props) {
        super(props);
        bindAll(this, [
            "hideCameraPopup"
        ]);
        this.state= {
            ...initialState
        }
    };

    componentDidMount () {
        navigator.permissions.query({ name: "camera" }).then(r => {
            this.setState({ showCameraPopup: r.state !== "granted" });
        });
    }


    componentDidUpdate() {
        if (this.props.vm.runtime.ioDevices) {
            this.props.vm.runtime.ioDevices.video.setPreviewGhost(0);
        }
        if (typeof this.state.showCameraPopup !== "undefined" && !this.state.showCameraPopup) {
            if (!this.props.loading && !this.props.defaultLoaded) {
                this.props.vm.loadProject(defaultProject)
                .then(res => {
                    console.log("Loaded good");
                    this.props.loadDefaultProject();
                }).catch(res => { 
                    console.log(res);
                });
            }
        }
    };

    hideCameraPopup() {
        this.setState({ showCameraPopup: false });
    }

    render () {
        if (typeof this.state.showCameraPopup !== "undefined" && this.state.showCameraPopup) {
            return (
                <>
                    <CameraModal hideCameraPopup={this.hideCameraPopup}/>
                </>
            )
        }

        return <></>;
    }
}

const mapStateToProps = state => ({
    defaultLoaded: state.scratchGui.tutorials.defaultLoaded
});

const mapDispatchToProps = dispatch => ({
    loadDefaultProject: () => dispatch(loadDefaultProject()),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(initialLoad);