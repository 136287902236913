
//Constants
const TUTORIAL_LOADED = "scratch-gui/tutorial/projectLoaded";
const DEFAULT_PROJECT_LOADED = "scratch-gui/tutorial/defaultProjectLoaded";
const STEP_ADVANCE = "scratch-gui/tutorial/stepAdvance";
const SHOW_TUTORIAL = "scratch-gui/tutorial/showTutorial";

const initialState = {
    finished: false,
    loaded: false,
    currentStep: 0,
    totalSteps: 0,
    blockMap: {},
    modal: null,
    showExample: false,
    showCameraPopup: undefined,
    showTutorial: false,
    defaultLoaded: false,
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case TUTORIAL_LOADED: {
            return {...state, loaded: true, totalSteps: action.totalSteps}
        }

        case STEP_ADVANCE: {
            return {
                ...state, 
                currentStep: state.currentStep + 1 < state.totalSteps ? state.currentStep + 1 : state.currentStep,
                finished: state.currentStep + 1 >= state.totalSteps,
            }
        }

        case SHOW_TUTORIAL: {
            return {
                ...state, 
                showTutorial: true
            }
        }

        case DEFAULT_PROJECT_LOADED:
            return {
                ...state,
                defaultLoaded: true
            }

        default: 
            return state
    }
};

//Actions
const loadProject = (totalSteps) => {
    return {
        type: TUTORIAL_LOADED,
        totalSteps
    }
};

const loadDefaultProject = () => {
    return {
        type: DEFAULT_PROJECT_LOADED
    }
};

const advanceStep = () => {
    return {
        type: STEP_ADVANCE
    }
};

const showTutorial = () => {
    return {
        type: SHOW_TUTORIAL
    }
};

export {
    reducer as default,
    initialState as tutorialSectionInitialState,
    loadProject,
    advanceStep,
    showTutorial,
    loadDefaultProject
};