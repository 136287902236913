import React from "react";
import styles from "./CameraModal.css";
import { Button } from "reactstrap";

class CameraModal extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((r) => {
                const video = document.getElementById("video-preview");

                if ("srcObject" in video) {
                    video.srcObject = r;
                } else {
                    video.src = window.URL.createObjectURL(r);
                }

                video.onloadedmetadata = (e) => {
                    video.play();
                };
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className={styles.permissionSection}>
                <div>
                    <video
                        className={styles.videoPreview}
                        id="video-preview"
                    ></video>
                </div>

                <div className={styles.basicFlex}>
                    <p>
                        Webcam permission is needed to use the Machine Learning
                        module
                    </p>
                    <p style={{ fontWeight: 700, fontSize: "18px" }}>
                        Do you see yourself correctly?
                    </p>
                    <Button
                        size="lg"
                        className={styles.customAlertButton}
                        onClick={() => {
                            this.props.hideCameraPopup();
                        }}
                    >
                        Start
                    </Button>
                </div>
            </div>
        );
    }
}

export default CameraModal;
