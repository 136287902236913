exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CameraModal_basic-flex_ycTzk {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 33px;\n    margin-top: -35px;\n}\n\n.CameraModal_permission-section_CRm_A {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    z-index: 69000;\n    background-color: #E7F0FE;\n    -webkit-box-pack: space-evenly;\n    -webkit-justify-content: space-evenly;\n        -ms-flex-pack: space-evenly;\n            justify-content: space-evenly;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n\n.CameraModal_video-preview_2h9bp {\n    width: 472px;\n    height: 354px;\n    -webkit-transform: scale(-1, 1);\n        -ms-transform: scale(-1, 1);\n            transform: scale(-1, 1);\n    background-color: black;\n}\n\n/* Button */\n\n.CameraModal_custom-alert-button_2ZZ_M {\n    background-color: #5F95F7;\n    border: none;\n    font-weight: 700;\n    font-size: 18px;\n    padding: 0.8em 2.48em;\n    border-radius: 10px !important;\n    min-width: 271px !important;\n}\n\n.CameraModal_custom-alert-button_2ZZ_M:hover {\n    outline: solid 3px #4593ff !important;\n    outline-offset: -3px;\n    color: white;\n    background-color: #2580fd;\n}\n\n.CameraModal_custom-alert-button_2ZZ_M:active {\n    color: white !important;\n    background-color: #78b0ff !important;\n}", ""]);

// exports
exports.locals = {
	"basic-flex": "CameraModal_basic-flex_ycTzk",
	"basicFlex": "CameraModal_basic-flex_ycTzk",
	"permission-section": "CameraModal_permission-section_CRm_A",
	"permissionSection": "CameraModal_permission-section_CRm_A",
	"video-preview": "CameraModal_video-preview_2h9bp",
	"videoPreview": "CameraModal_video-preview_2h9bp",
	"custom-alert-button": "CameraModal_custom-alert-button_2ZZ_M",
	"customAlertButton": "CameraModal_custom-alert-button_2ZZ_M"
};