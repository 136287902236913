import React from 'react';
import {connect} from 'react-redux';
import styles from "./TutorialHeader.css";
import VM from 'scratch-vm';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import classNames from 'classnames';
import { advanceStep, loadProject } from '../../reducers/tutorials';
const tutorial = require("./test.json");
const questions = require("./questions.json");

const initialState = {
    finished: false,
    loaded: false,
    currentStep: 0,
    totalSteps: 0,
    blockMap: {},
    modal: null,
    showExample: false,
};

class TutorialHeaderComponent extends React.Component {

    constructor (props) {
        super(props);
        bindAll(this, [
            "checkGoals",
            "onProjectLoaded",
            "updateTutorialSteps",
            "checkBlockCondition",
            "checkBlockMonitor",
            "renderSweetAlert",
            "checkTutorialRendering",
            'onProjectStart',
            'onProjectChanged',
            'onBlockUpdate',
            'onMonitorsUpdate'
        ]);
        this.state = {
            ...initialState
        };
    };

    onProjectStart () {
        this.checkGoals('PROJECT_START');
    };

    onProjectChanged () {
        this.checkGoals('PROJECT_CHANGED');
    }

    onBlockUpdate () {
        this.checkGoals('BLOCK_DRAG_UPDATE');
    };

    onMonitorsUpdate () {
        this.checkGoals('MONITORS_UPDATE');
    };
    

    componentDidMount () {
        this.props.vm.addListener('BLOCK_DRAG_UPDATE', this.onBlockUpdate);
        this.props.vm.addListener('MONITORS_UPDATE', this.onMonitorsUpdate);
        this.props.vm.addListener('PROJECT_CHANGED', this.onProjectChanged);
        this.props.vm.addListener('PROJECT_START', this.onProjectStart);

        this.onProjectLoaded();
        this.checkTutorialRendering();
    }

    //Re-render only if loading prop went from true to false, or if the project has been loaded and the state changes
    shouldComponentUpdate(nextProps, nextState) {
        return Object.keys(nextState).some(s  => {
            if (nextState[s] !== this.state[s]) {
                return true;
            }
        });
    }

    componentWillUnmount() {
        this.props.vm.removeListener('BLOCK_DRAG_UPDATE', this.onBlockUpdate);
        this.props.vm.removeListener('MONITORS_UPDATE', this.onMonitorsUpdate);
        this.props.vm.removeListener('PROJECT_CHANGED', this.onProjectChanged);
        this.props.vm.removeListener('PROJECT_START', this.onProjectStart);
    }

    componentDidUpdate() {
        this.checkGoals();
        this.checkTutorialRendering();
    };

    onProjectLoaded() {
        //Load the tutorial project once.
        //TODO: Set this somewhere else
        if (!this.props.loaded) {
            this.props.vm.loadProject(tutorial.initialState)
            .then(res => {
                this.props.projectLoaded(tutorial.steps.length);
                this.setState({
                    modal: (
                        <SweetAlert
                            custom
                            title={tutorial.initialPopup.title}
                            customClass={classNames(styles.sweetAlertTutorial, styles.initialPopup) }
                            customButtons={(
                                <Button
                                    size="lg"
                                    className={styles.customAlertButton}
                                    onClick={() => { this.renderSweetAlert(null); this.checkTutorialRendering();}}
                                >
                                    {tutorial.initialPopup.buttonPrompt}
                                </Button>
                            )}
                        >
                            <div className={styles.basicFlex}>
                                <div>{tutorial.initialPopup.description}</div>
                                <div className={styles.tutorialPreviewImage} />
                            </div>
                        </SweetAlert>
                    )
                });
            }).catch(res => { 
                this.setState({loaded: false});
            });
        }
    }

    checkGoals(event = "local") {
        if (!this.props.vm.runtime.targets[1] || this.props.finished) {
            return;
        }

        //Check the goals
        const passed = Object.keys(tutorial.steps[this.props.currentStep].goal).every(key => {
            switch (key) {
                case "blocks":
                    return this.checkBlockCondition(key, event);
                    
                case "monitor":
                    return this.checkBlockMonitor(key);
            }
        });

        if (passed) {
            this.updateTutorialSteps(event);
        }
    };


    //Little form submit
    /*
    onSubmit (e) {
        e.preventDefault();
        const data = Object.fromEntries(new FormData(e.target));
        let totalQuestions = 0;
        let correctQuestions = 0;

        //Count the correct answers and total
        questions.map((q, i) => {   
            const givenAnswer = Number(data[`q${i}`]);
            totalQuestions++;

            if (givenAnswer === q.correct_answer) {
                correctQuestions++;
            }
        });

        this.renderSweetAlert((
        <SweetAlert
            title="Your results!"
            error={!correctQuestions}
            onConfirm={() => {this.renderSweetAlert(null);}}
        >
            <div className={styles.questionHeader}>
                Correct answers: 
                <span 
                style={{color: !correctQuestions ? "red" : "green"}}>
                    {correctQuestions}
                </span>
                /
                {totalQuestions}
            </div>

            {
                questions.map((q, i) => {   
                const givenAnswer = Number(data[`q${i}`]);

                if (givenAnswer !== q.correct_answer) {
                    const answer = q.answers.find(a => a.id === givenAnswer);
                    const correctAnswer = q.answers.find(a => a.id === q.correct_answer);
                    return (<>
                        <div className={styles.questionHeader}>Question {i+1}: {q.question}</div>
    
                        <div className={styles.answerContainer}>
                            <p>Your Answer: <span style={{color: "red"}}>{answer.text} &#x2716;</span></p>
                            <p>Correct Answer: <span style={{color: "green"}}>{correctAnswer.text} &#x2714;</span></p>
                        </div>
                    </>)
                }
                
            })}
        </SweetAlert>
        ));
    }
    */

    //Update the state of the tutorial
    updateTutorialSteps (event) {
        if (['local', 'PROJECT_CHANGED'].includes(event)) {
            this.props.advanceStep();
        }
        this.setState((prev) => { 
            return {
                showExample: false,
                modal: (
                    this.props.currentStep + 1 >= this.props.totalSteps ? (
                        <SweetAlert
                            custom
                            title="Tutorial Complete!"
                            closeOnClickOutside={false}
                            customClass={styles.sweetAlertTutorial}
                            customButtons={(
                                <Button
                                    size="lg"
                                    className={styles.customAlertButton}
                                    onClick={() => { this.renderSweetAlert(null); this.checkTutorialRendering();}}
                                >
                                    Try your classifier out!
                                </Button>
                            )}
                        >
                            <div className={styles.basicFlex} >
                                <img className={styles.modalIconWidth} src="./static/robot-vector-hd.svg"/>
                                Kudos on the great work!
                            </div>
                            
                            {/* Questionary */}
                            {/*
                            <Form onSubmit={this.onSubmit}>
                            {questions.map((q, i) => {
                                return (
                                    <FormGroup check className={styles.questionContainer} key={`form-container-${i}`}>
                                        <div className={styles.questionHeader}>{q.question}</div>

                                        {/* Shuffling the answers 
                                        {q.answers
                                        .map(answer => ({ answer, order: Math.random()}))
                                        .sort((l, r) => l.order - r.order)
                                        .map(({answer}, j) => 
                                            <Label 
                                                className={styles.questionRow} 
                                                key={`label${i}-${j}`} 
                                                id={`q${i}-${answer.text}`} 
                                            >
                                                <Input 
                                                    type="radio" 
                                                    name={`q${i}`} 
                                                    value={answer.id} 
                                                    className={styles.refinedCheckInput}
                                                    key={`q${i}-${answer.text}-${j}`}
                                                />
                                                {answer.text}
                                            </Label>
                                        )}
                                    </FormGroup>
                                );
                            })}

                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                            </Form>
                            */}
                        </SweetAlert>
                    ) : (
                        <SweetAlert
                            custom
                            title="Brilliant Work!"
                            customClass={styles.sweetAlertTutorial}
                            confirmBtnText="Continue to next step"
                            customButtons={(
                                <Button
                                    size="lg"
                                    className={styles.customAlertButton}
                                    onClick={() => { 
                                        this.renderSweetAlert(null); 
                                        this.checkTutorialRendering();
                                    }}
                                >
                                    Continue to next step
                                </Button>
                            )}
                        >
                            <div className={styles.basicFlex} >
                                <img className={styles.modalIconWidth} src="./static/robot-vector-hd.svg"/>
                            </div>
                        </SweetAlert>
                    )
                    
                )
        }});
    };

    //Check for the existence of blocks in the given target
    checkBlockCondition (key, event) {
        const target = tutorial.steps[this.props.currentStep].goal[key].target;
        const goalBlocks = tutorial.steps[this.props.currentStep].goal[key].state;
        const blocks = {...this.props.vm.runtime.targets[target].blocks};

        if (
            tutorial.steps[this.props.currentStep].goal[key].fieldCheck || 
            tutorial.steps[this.props.currentStep].goal[key].connections
            ) {
            //Return the check on each project change
            return this._blockCheckHelper(blocks._blocks, goalBlocks, true);

        } else if(event !== 'PROJECT_CHANGED') {
            //Return the check if a block drag event was finished
            return this._blockCheckHelper(blocks._blocks, goalBlocks, false);
        }
    }

    _blockCheckHelper(blocks, goals, checkFields) {
        return goals.every(block => {
            //For each block condition, check if the block and the parent exists

            //Find a block that exists on the map
            let targetBlock = blocks[Object.keys(blocks).find(key => 
                blocks[key].opcode === block.name && this.state.blockMap[block.id] === blocks[key].id
            )];


            //Perform an update based on the event received by the VM (if drag event, update now, if not, later)
            //TODO: Do something better
            if (!checkFields) {
                //If block doesn't exists, save the ID of the block
                if (!targetBlock && !this.state.blockMap[block.id]) {
                    targetBlock = blocks[Object.keys(blocks).find(key => {
                        return blocks[key].opcode === block.name && !(Object.keys(this.state.blockMap).find(num => 
                            this.state.blockMap[num] === blocks[key].id
                            ));
                    })];

                    //Saving
                    if (targetBlock) {
                        let newList = {...this.state.blockMap};
                        newList[block.id] = targetBlock.id;
                        this.setState({
                            blockMap: newList
                        });
                    }
                }
            }

            //Check if the block requires a parent block to be present
            const parentBlock = block.parent && targetBlock && targetBlock.parent 
            ? this.state.blockMap[block.parent] === targetBlock.parent 
            : false;

            const passed = typeof targetBlock !== "undefined" && (block.parent ? parentBlock : true);
            let checkPassed = true;

            if (checkFields) {
                //If block doesn't exists, save the ID of the block
                if (!targetBlock && !this.state.blockMap[block.id]) {
                    targetBlock = blocks[Object.keys(blocks).find(key => {
                        return blocks[key].opcode === block.name && !(Object.keys(this.state.blockMap).find(num => 
                            this.state.blockMap[num] === blocks[key].id
                            ));
                    })];

                    //Saving
                    if (targetBlock) {
                        let newList = {...this.state.blockMap};
                        newList[block.id] = targetBlock.id;
                        this.setState({
                            blockMap: newList
                        });
                    }
                }
            }
            

            //Check if text/number fields match are needed
            if (checkFields && targetBlock) {

                //Check for any field that requires a match
                checkPassed = Object.keys(block).length ? Object.keys(block).every(field => {
                    if (['text', 'math_number'].includes(field)) {

                        const blockField = blocks[Object.keys(blocks).find(key => 
                            typeof blocks[key].parent !== "undefined" &&
                            blocks[key].parent === targetBlock.id &&
                            blocks[key].opcode === field
                        )];
        
                        return blockField && blockField
                        .fields[block.text ? "TEXT" : block.math_number ? "NUM" : "TEXT"]
                        .value
                        .toLowerCase() === block[field];
                    } else {
                        return true;
                    }
                }) : true;
            }

            return passed && checkPassed;
        });
    }

    //Check for the existence of reporters
    checkBlockMonitor (key) {
        const reporters =  tutorial.steps[this.props.currentStep].goal[key];
        const monitorBlocks = this.props.vm.runtime.getMonitorState();

        return reporters.every(reporter => {
            const reportBlock = monitorBlocks.get(reporter.name);
            const currentValue = reportBlock ? reportBlock.value : null;
            
            return reportBlock 
            && reportBlock.visible === true 
            && (reporter.value ? Number(currentValue) === Number(reporter.value) : true);
        });
    }

    //Render sweetAlert
    renderSweetAlert (alert) {
        this.setState({modal: (alert)});

        this.checkTutorialRendering();
    };

    //Check if the tutorial is being rendered correctly, if not, re-render
    checkTutorialRendering() {
        if (!document.querySelector("tutorial > span > svg")) {
            //Re-render the block parser
            scratchblocks.renderMatching('tutorial.block', {
                style:     'scratch3',
                inline: true,
                scale: 0.575,
            });
        }

        //Render the example button
        if (!this.state.showExample) {
            ["help1", "help2", "help3"].some(help => {
                if(document.getElementById(help)) {
                    this.setState({showExample: help});
                }

                return document.getElementById(help);
            })
        }

        //Set the height for CSS calculations
        const root = document.documentElement;
        if (root && document.getElementById("tutorial-header")) {
            root.style.setProperty("--tutorial-height", `${document.getElementById("tutorial-header").clientHeight}px`);

            //Tell scratch GUI to resize the stage wrappers
            window.dispatchEvent(new Event('resize'));
        }

        //Set video transparency to 0
        if (this.props.vm.runtime.ioDevices) {
            this.props.vm.runtime.ioDevices.video.setPreviewGhost(0);
        }
    };

    //Handle the help image on the modal
    showExampleAlert() {
        let image = null;
        switch(this.state.showExample) {
            case "help1":
                image = "./static/rock-example.png"
            break;
            case "help2":
                image = "./static/paper-example.png"
            break;
            case "help3":
                image = "./static/scissor-example.png"
            break;
        };

        this.setState({
            modal: (
                <SweetAlert
                    title="Example"
                    customClass={styles.sweetAlertWidth}
                    onConfirm={() => {this.renderSweetAlert(null); this.checkTutorialRendering(); }}
                >
                    <div className={styles.exampleContainer}>
                        <p>Show different angles to train the AI as shown below.</p>
                        <img src={image} width={500} height={500} />
                    </div>
                </SweetAlert>
            )
        })
        
    }

    render () {

        //If no sprite and no extension is loaded, ignore
        if (!this.props.vm.runtime.targets[1] || !this.props.vm.runtime._blockInfo.length) {
            return (<></>);
        }

        return (
            <>
                {this.state.modal}

                
                <div className={styles.tutorialHeaderSection} id="tutorial-header">

                    <div 
                        className={styles.tutorialSegment}
                    >
                        <div 
                            className={styles.tutorialSegmentText}
                            dangerouslySetInnerHTML={
                                {__html: 
                                this.props.finished ? 
                                tutorial.finalMessage : 
                                tutorial.steps[this.props.currentStep].title 
                                }
                            }
                        />

                        <div className={styles.tutorialBulletPoint}/>
                    </div>

                    {!this.props.finished && (
                        <div 
                        className={styles.tutorialSegment}
                        >
                            <div 
                                className={styles.tutorialSegmentText}
                                dangerouslySetInnerHTML={
                                    {__html: 
                                    tutorial.steps[this.props.currentStep].description 
                                    }
                                }
                            />
                            
                            <div className={styles.tutorialBulletPoint}/>

                            {this.state.showExample && (
                            <Button
                                color="info"
                                className={styles.tutorialSegmentHint}
                            >
                                HINT
                            </Button>
                            )}
                        </div>
                    )}
                
                </div>
                
            </>
        );
    }
};

TutorialHeaderComponent.propTypes = {
    vm: PropTypes.instanceOf(VM)
};

const mapStateToProps = state => ({
    loaded: state.scratchGui.tutorials.loaded,
    currentStep: state.scratchGui.tutorials.currentStep,
    totalSteps: state.scratchGui.tutorials.totalSteps,
    finished: state.scratchGui.tutorials.finished
});

const mapDispatchToProps = dispatch => ({
    projectLoaded: totalSteps => dispatch(loadProject(totalSteps)),
    advanceStep: () => dispatch(advanceStep())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TutorialHeaderComponent);